<template>
    <div id="list-users-component" class="w-100 h-100">
        <div v-if="enabled">
            <span v-if="isLoading"> Cargando...</span>
            <div v-else class="d-flex align-items-end justify-content-between">
                <div class="w-100 h-100 d-flex flex-column">
                    <label for="user">{{ label }}</label>
                    <vs-select
                        v-if="options.length"
                        v-model="option"
                        border
                        shadow
                        filter
                        name="user"
                        :state="stateInputDark"
                        class="w-100 mw-100 border-custom"
                        @input="onInput"
                    >
                        <vs-option
                            v-for="(item, i) in options"
                            :key="i"
                            :label="`${item.firstName} ${item.lastName}`"
                            :value="item.id"
                        >
                            {{ `${item.firstName} ${item.lastName}` }}
                        </vs-option>
                    </vs-select>
                    <span v-else> No se han encontrado resultados </span>
                </div>

                <vs-button circle icon floating @click="reloadItems">
                    <reload-icon />
                </vs-button>
            </div>
        </div>

        <div v-else class="d-flex flex-column h-100 w-100 mw-100">
            <div class="position-relative">
                <label class="left-13px"> Usuario </label>
            </div>
            <div class="d-flex px-2 align-items-center h-100">
                <span class="ml-2">
                    {{ `${itemCurrent.firstName} ${itemCurrent.lastName}` }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import ReloadIcon from "@/components/icons/ReloadIcon.vue";

export default {
    name: "BrandSelector",
    components: { ReloadIcon },
    model: {
        prop: "itemCurrent",
        event: "change"
    },
    props: {
        itemCurrent: {
            default: () => ({
                id: "",
                email: ""
            }),
            type: Object,
            require: true
        },
        enabled: {
            default: () => true,
            type: Boolean,
            required: false
        },
        fill: {
            default: () => false,
            type: Boolean,
            required: false
        },
        label: {
            default: () => "Usuario",
            type: String,
            required: false
        }
    },
    emits: ["change"],
    data: () => ({
        option: "",
        options: [],
        isLoading: true
    }),
    computed: {
        ...mapGetters("control", ["stateInputDark", "backgroundColor"]),
        ...mapState("commons", ["selectedMerchant"])
    },
    watch: {
        itemCurrent(value) {
            this.setInput(value);
        }
    },
    mounted() {
        this.setInput(this.itemCurrent);
        if (this.fill) {
            this.reloadItems(true);
        }
    },
    methods: {
        ...mapActions("users", ["listUsersMerchant"]),
        async reloadItems(required = true) {
            this.isLoading = true;
            try {
                this.options = await this.listUsersMerchant({
                    required,
                    merchantId: this.selectedMerchant.merchantId,
                    merchantType: this.selectedMerchant.merchantType
                });
            } catch (error) {
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.isLoading = false;
            }
        },
        setInput(value) {
            const aux = this.options.find((item) => item.id === value.id);
            if (!aux) {
                this.options = [value];
            }
            this.option = value.id ?? "";
            this.isLoading = false;
        },
        onInput() {
            const aux = this.options.find((item) => this.option === item.id);
            if (aux && this.option) {
                this.$emit("change", aux);
            }
        }
    }
};
</script>
